<template>
  <div class="administrativo q-pa-md">
    <div>
      <!-- NOVO CLIENTE -->
      <q-expansion-item
        group="adminGroup"
        icon="fas fa-user-plus"
        label="Cadastrar Novo Cliente"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section>
            <div class="respRowForm">
              <q-input dense outlined class="threeFields" v-model="responsible" type="text" label="Responsável" />
              <q-input dense outlined class="threeFields" v-model="mail" type="mail" label="E-Mail Usuário" />
              <q-input dense outlined class="threeFields" v-model="pass" :type="isPwd ? 'password' : 'text'" label="Gerar Senha">
                <template v-slot:append>
                  <q-icon
                    :name="isPwd ? 'visibility' : 'visibility_off'"
                    @click="isPwd = !isPwd"
                  />
                </template>
              </q-input>
            </div>

            <div class="respRowForm q-my-sm">
              <q-input dense outlined class="threeFields" v-model="empresa" type="text" label="Empresa" />
              <q-input dense outlined class="threeFields" v-model="phone" type="text" label="Celular" />
              <q-select dense outlined class="threeFields" v-model="active" :options="['Sim', 'Não']" label="Ativo?" />
            </div>

            <div class="respRowForm">
              <q-select dense outlined class="threeFields" v-model="frequency" :options="optPgto" label="Frequencia Pagamento" />
              <q-input dense outlined class="threeFields" v-model="method" type="text" label="Forma de Pagamento" />
              <q-input dense outlined class="threeFields" v-model="dueday" type="number" label="Dia de Vencimento" />
            </div>
          </q-card-section>
          <q-card-section>
            <div class="text-bold text-grey-5 text-h6">
              {{ `Clientes Ativos: ${activeUsers}` }}
            </div>
          </q-card-section>
          <q-card-section>
            <q-btn no-caps label="Cadastrar Novo Cliente" @click="onNewUser" style="background-color: var(--primary); color: white;" />
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

      <!-- BLOQUEAR CLIENTES -->
      <q-expansion-item
        group="adminGroup"
        icon="fas fa-user-lock"
        label="Bloquear / Desbloquear Clientes"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section>

            <div class="respRowForm q-my-sm">
              <q-select dense outlined class="twoFields" v-model="company" :options="optEmpresa" label="Cliente" @blur="onEmpresa" />
              <q-select dense outlined class="twoFields" v-model="stts" :options="['Ativo', 'Inativo']" label="Ativo?" />
            </div>

          </q-card-section>
          <q-card-section>
            <q-btn no-caps label="Gravar Alteração" @click="onChangeStatus" style="background-color: var(--primary); color: white;" />
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

      <!-- VISUALIZAR CLIENTES -->
      <q-expansion-item
        group="adminGroup"
        icon="fas fa-binoculars"
        label="Visualizar Clientes"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section>
            <div class="table">
              <div class="text-caption text-red text-right">
                Clientes bloqueados são marcados com "*" no campo "Ativo?"
              </div>
              <q-table
                dense
                class="myTable"
                :rows="rowsClientes"
                :columns="colsClientes"
                row-key="name"
                :rows-per-page-options="[10, 20, 50, 100, 99999]"
                rows-per-page-label="Linhas por página: "
                :filter="filterClientes"
                @row-click="onEditCli"
                no-results-label="Sem dados a serem mostrados"
                no-data-label="Sem dados a serem mostrados"
              >
                <template v-slot:top-left>
                  <q-input filled dense dark v-model="filterClientes" placeholder="Pesquisar" style="width: 150px">
                    <template v-slot:append>
                      <q-icon name="search" />
                    </template>
                  </q-input>
                </template>
              </q-table>
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

      <!-- ALTERAR EMPRESA -->
      <q-expansion-item
        group="adminGroup"
        icon="fas fa-people-arrows"
        label="Trocar de Loja"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section>
            <div class="table">
              <div class="respRowForm q-my-sm">
                <p class="threeFields">Renan: </p>
                <q-select class="threeFields" dense outlined v-model="alterEnterpriseR" :options="optEmpresa" label="Cliente" @blur="onEmpresa" />
                <q-btn class="threeFields" outline label="Alterar" @click="onAlterEnterprise('Renan')" />
                <q-btn class="threeFields" outline label="Voltar ADMIN" @click="onBackAdmin('Renan')" />
              </div>
              <div class="respRowForm q-my-sm">
                <p class="threeFields">Wesley: </p>
                <q-select class="threeFields" dense outlined v-model="alterEnterpriseW" :options="optEmpresa" label="Cliente" @blur="onEmpresa" />
                <q-btn class="threeFields" outline label="Alterar" @click="onAlterEnterprise('Wesley')" />
                <q-btn class="threeFields" outline label="Voltar ADMIN" @click="onBackAdmin('Wesley')" />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

      <!-- TRATATIVA DE CHAMADOS -->
      <q-expansion-item
        group="adminGroup"
        icon="fas fa-envelope"
        label="Chamados / Contatos Realizados"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section>
            <div class="table">
              <q-table
                dense
                class="myTable"
                :rows="rows"
                :columns="cols"
                row-key="name"
                :rows-per-page-options="[10, 20, 50, 100, 99999]"
                rows-per-page-label="Linhas por página: "
                :filter="filter"
                no-results-label="Sem dados a serem mostrados"
                no-data-label="Sem dados a serem mostrados"
                @row-click="onEditRow"
              >
                <template v-slot:top-left>
                  <q-input filled dense dark v-model="filter" placeholder="Pesquisar" style="width: 150px">
                    <template v-slot:append>
                      <q-icon name="search" />
                    </template>
                  </q-input>
                </template>
              </q-table>
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

      <!-- VISUALIZAÇÃO DOS LOGS DE ACESSOS -->
      <q-expansion-item
        group="adminGroup"
        icon="fas fa-history"
        label="LOG de Acessos"
        header-class="bg-verde text-white"
        expand-icon-class="text-white"
      >
        <q-card class="bg-white bLBR">
          <q-card-section>
            <div class="table">
              <q-table
                dense
                class="myTable"
                :rows="rowsLOG"
                :columns="colsLOG"
                row-key="name"
                :rows-per-page-options="[10, 20, 50, 100, 99999]"
                rows-per-page-label="Linhas por página: "
                :filter="filter"
                no-results-label="Sem dados a serem mostrados"
                no-data-label="Sem dados a serem mostrados"
              >
                <template v-slot:top-left>
                  <q-input filled dense dark v-model="filter" placeholder="Pesquisar" style="width: 150px">
                    <template v-slot:append>
                      <q-icon name="search" />
                    </template>
                  </q-input>
                </template>
              </q-table>
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-space class="q-mt-xs" />

    </div>

    <!-- MODAL PARA EDIÇÃO DOS CHAMADOS -->
    <q-dialog v-model="modalAdm" full-width persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div class="title text-h5 text-bold text-center text-verde q-mb-md">
          Editando Chamados
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />
        
        <q-scroll-area style="width: 100%; height: 65vh;">
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="threeFields" outlined v-model="subject" type="text" label="Assunto" />
            <q-input class="threeFields" outlined v-model="email" type="mail" label="Email" />
            <q-input class="threeFields" outlined v-model="celphone" type="text" label="Celular" />
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="twoFields" outlined v-model="category" type="text" label="Assunto" />
            <q-select class="twoFields" outlined v-model="status" :options="['PENDENTE', 'EM TRATATIVA', 'FINALIZADO']" label="Status" />
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="oneFields" outlined v-model="msg" type="textarea" label="Mensagem" />
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="oneFields" outlined v-model="resp" type="textarea" label="Acompanhamentos" />
          </div>
        </q-scroll-area>

        <div class="btns row justify-center q-pt-md">
          <q-btn outline no-caps color="negative" label="Cancelar" @click="onCancel" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="primary" label="Salvar" @click="onSave" />
        </div>
      </div>
    </q-dialog>

    <!-- MODAL PARA EDIÇÃO DOS CLIENTES -->
    <q-dialog v-model="modalCli" persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div class="title text-h5 text-bold text-center text-verde q-mb-md">
          Inativando Cliente
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />
        
        <!-- <q-scroll-area style="width: 100%; height: 65vh;"> -->
        <q-scroll-area style="width: 200px; height: 100px;">
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-select
              class="oneFields"
              outlined
              v-model="ativo"
              :options="['Sim', 'Não']"
              label="Cliente Ativo?"
              stack-label
              @blur="onStatusCLI"
            />
          </div>
        </q-scroll-area>

        <div class="btns row justify-center q-pt-md">
          <q-btn outline no-caps color="negative" label="Cancelar" @click="onCancelCli" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="primary" label="Salvar" @click="onSaveCli" />
        </div>
      </div>
    </q-dialog>
  </div>
</template>

<script>
import { supabase } from '../../supabase';
import { mapActions } from 'vuex';
import { defaultColor } from '../../helpers/defaultColor';
import { Dialog } from 'quasar';

export default {
  data() {
    return {
      activeUsers: null,
      isPwd: true,
      responsible: null,
      mail: null,
      pass: null,
      empresa: null,
      phone: null,
      active: null,
      frequency: null,
      method: null,
      dueday: null,
      optPgto: [
        'Avaliação', 'Gratuito', 'Mensal', 'Trimestral', 'Semestral', 'Anual'
      ],
      filter: null,
      cols: [
        { name: 'RECNO',          align: 'center', label: 'Seq',          field: 'RECNO',         sortable: true },
        { name: 'created_at',     align: 'center', label: 'Data Criação', field: 'created_at',    sortable: true,
          format: val => String(val).substring(0, 10).replaceAll('-', '/')
        },
        { name: 'GE_SUBJECT',     align: 'center', label: 'Assunto',      field: 'GE_SUBJECT',    sortable: true,
          format: val => String(val).substring(0, 50)
        },
        { name: 'GE_MAIL',        align: 'center', label: 'E-Mail',       field: 'GE_MAIL',       sortable: true },
        { name: 'GE_PHONE',       align: 'center', label: 'Celular',      field: 'GE_PHONE',      sortable: true },
        { name: 'GE_CATEGORY',    align: 'center', label: 'Categoria',    field: 'GE_CATEGORY',   sortable: true },
        { name: 'GE_ENTERPRISE',  align: 'center', label: 'Empresa',      field: 'GE_ENTERPRISE', sortable: true },
        { name: 'STATUS',         align: 'center', label: 'STATUS',       field: 'STATUS',        sortable: true },
      ],
      rows: [],

      //~> Para Modal
      modalAdm: false,
      subject: null,
      email: null,
      celphone: null,
      category: null,
      msg: null,
      status: null,
      resp: null,
      recno: null,

      //~> Para Ativar e Inativar Clientes
      company: null,
      stts: null,
      optEmpresa: [],
      optStatus: [],

      //~> Visualizar Clientes
      colsClientes: [
        { name: 'responsavel',    align: 'center', label: 'Resposável',       field: 'responsavel',     sortable: true },
        { name: 'email',          align: 'center', label: 'E-Mail',           field: 'email',           sortable: true },
        { name: 'empresa',        align: 'center', label: 'Empresa',          field: 'empresa',         sortable: true },
        { name: 'celular',        align: 'center', label: 'Celular',          field: 'celular',         sortable: true },
        { name: 'ativo',          align: 'center', label: 'Ativo?',           field: 'ativo',           sortable: true },
        { name: 'frequencia',     align: 'center', label: 'Frequencia',       field: 'frequencia',      sortable: true },
        { name: 'frm_pgto',       align: 'center', label: 'Forma Pagamento',  field: 'frm_pgto',        sortable: true },
        { name: 'dia_vencimento', align: 'center', label: 'Dia Vencimento',   field: 'dia_vencimento',  sortable: true },
        // { name: 'data_acesso',    align: 'center', label: 'Último Acesso',    field: 'data_acesso',     sortable: true },
      ],
      rowsClientes: [],
      filterClientes: null,

      //~> Para visualização dos LOGs de Acesso
      colsLOG: [
        { name: 'email',           align: 'center', label: 'Usuário',        field: 'email',          sortable: true },
        { name: 'empresa',         align: 'center', label: 'Empresa',        field: 'empresa',        sortable: true },
        { name: 'data_acesso',     align: 'center', label: 'Data de Acesso', field: 'data_acesso',    sortable: true },
        { name: 'hora_acesso',     align: 'center', label: 'Hora de Acesso', field: 'hora_acesso',    sortable: true },
      ],
      rowsLOG: [],
      alterEnterpriseR: null,
      alterEnterpriseW: null,

      modalCli: false,
      ativo: null,
      statusCli: null,
      idCLI: null,

    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    },
  },
  methods: {
    ...mapActions('Querys', [ 'insertData', 'updateData', 'getDataWithoutFilters' ]),
    ...mapActions('Functions', [ 'rpc' ]),
    async onNewUser() { //~> OK

      //~> GRAVANDO NA TABELA 
      let act = this.active == 'Sim' ? ' ' : '*';
      await this.insertData({
        table: 'GE_PERFIL_USERS',
        fields: {
          'GE_RESPONSIBLE': this.responsible,
          'GE_EMAIL': this.mail,
          'GE_ENTERPRISE': this.empresa,
          'GE_PHONE': this.phone,
          'GE_FREQUENCY': this.frequency,
          'GE_PAYMETHOD': this.method,
          'GE_DUEDATE': this.dueday,
          'DELETED': act,
          'GE_DEFAULTCOLOR': 'default'
        }
      })

      //~> GRAVANDO MISSAO VISSAO E VALORES PADRÕES
      let visao = 'Garantir um modelo transparente e com qualidade de atendimento e venda de artigos eletrônicos por todo o Brasil.'
      let missao = 'Um novo conceito de lojas de artigos eletrônicos. Diversas opções a apenas um clique de você. Nossa loja busca aliar bom atendimento, qualidade e preço justo pelo produto.'
      let valor = 'Vendas com base na ética e profissionalismo, proporcionando uma experiencia satisfatória em lojas onlines.'

      await this.insertData({
        table: 'GE_SETTINGS',
        fields: {
          'GE_MISSAO': missao,
          'GE_VISAO': visao,
          'GE_VALORES': valor,
          'GE_ENTERPRISE': this.empresa,
          'DELETED': ' '
        }
      })

      //~> REGISTRANDO NAS AUTENTICAÇÕES
      await supabase.auth.signUp({
        email: this.mail,
        password: this.pass
      })

      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Oba!!! Mais um usuário registrado no melhor sistema de Gestão...'
      })

    },
    onCancel() { //~> OK
      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Deseja realmente sair? Ao sair o preenchimento será perdido.',
        persistent: true,
        cancel: true,
      }).onOk(() => {
        this.modalAdm = false;
      })
    },
    onEditRow(_, row) { //~> OK
      this.modalAdm = true;

      this.subject = row.GE_SUBJECT
      this.email = row.GE_MAIL
      this.celphone = row.GE_PHONE
      this.category = row.GE_CATEGORY
      this.msg = row.GE_MESSAGE
      this.status = row.STATUS
      this.resp = row.GE_FOLLOWUP
      this.recno = row.RECNO

    },
    onEditCli(_, row) {
      this.idCLI = row.id
      this.modalCli = true;
    },
    onStatusCLI() {
      if(this.ativo === 'Sim') {
        this.statusCli = ' ';
      } else {
        this.statusCli = '*'
      }
    },
    onCancelCli() {
      this.modalCli = false;
    },
    async onSaveCli() {
      await this.updateData({
        table: 'GE_PERFIL_USERS',
        fields: {
          'DELETED': this.statusCli,
        },
        match: {
          'RECNO': this.idCLI
        }
      });

      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Cliente ajustado com sucesso!'
      })

      this.executeQuery();
      this.modalCli = false;
    },
    onEmpresa() { //~> OK
      this.optStatus.forEach(opt => {
        if( opt.empresa == this.company ) {
          this.stts = opt.status
        }
      });
    },
    async onChangeStatus() {
      let status_ajustado;
      if(this.stts === 'Ativo') {
        status_ajustado = ' ';
      } else {
        status_ajustado = '*';
      }

      await this.updateData({
        table: 'GE_PERFIL_USERS',
        fields: {
          'DELETED': status_ajustado,
        },
        match: {
          'GE_ENTERPRISE': this.company
        }
      });

      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Cliente ajustado com sucesso!'
      })

      this.executeQuery();
    },
    async onSave() { //~> OK
      await this.updateData({
        table: 'GE_CONTACTS',
        fields: {
          'GE_SUBJECT': this.subject,
          'GE_MAIL': this.email,
          'GE_PHONE': this.celphone,
          'GE_CATEGORY': this.category,
          'GE_MESSAGE': this.msg,
          'STATUS': this.status,
          'GE_FOLLOWUP': this.resp
        },
        match: {
          'RECNO': this.recno
        }
      })

      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Contato alterado com sucesso!'
      });

      this.modalAdm = false;
      this.executeQuery();

    },
    async executeQuery() { //~> OK
      //~> PARA ALIMENTAR A TABELA
      let contacts = await supabase
        .from('GE_CONTACTS')
        .select('*')
        .order('RECNO', { ascending: true })

      this.rows = contacts.data

      //~> PARA BLOQUEIO E DESBLOQUEIO DE CLIENTES

      let stts = await this.rpc({
        function: 'bloq_clients'
      })

      this.optStatus = stts;

      this.optEmpresa = [];
      stts.forEach(stt => {
        this.optEmpresa.push(stt.empresa)
      });

      //~> FUNCTION PARA HISTÓRICO DOS CLIENTES
      // let historico = await this.rpc({
        //   function: 'log_acessos'
      // })

      let historico = await supabase
        .from('GE_PERFIL_USERS')
        .select('*')
        .filter('DELETED', 'neq', '*')
        .in('GE_FREQUENCY', [
          'Mensal', 'Trimestral', 'Semestral', 'Anual'
        ])

      this.rowsClientes = historico.data.map(row => {
        return {
          responsavel: row.GE_RESPONSIBLE,
          email: row.GE_EMAIL,
          empresa: row.GE_ENTERPRISE,
          celular: row.GE_PHONE,
          ativo: row.DELETED === '*' ? 'Não' : 'Sim',
          frequencia: row.GE_FREQUENCY,
          frm_pgto: row.GE_PAYMETHOD,
          dia_vencimento: row.GE_DUEDATE
        }
      });

      //~> PARA LOGS DE ACESSOS
      let logs = await supabase
        .from('log_acessos')
        .select('*')

      this.rowsLOG = logs.data;

      //~> EMPRESA ATUAL DOS ADMINS
      let atualEmpresa = await supabase
        .from('GE_PERFIL_USERS')
        .select('GE_EMAIL, GE_ENTERPRISE')
        .filter('GE_EMAIL', 'in', '("gestaoexcel.oficial@gmail.com","wesleyribeiro123@gmail.com")')

      atualEmpresa.data.forEach(empresa => {
        if(empresa.GE_EMAIL === 'gestaoexcel.oficial@gmail.com') {
          this.alterEnterpriseR = empresa.GE_ENTERPRISE
        } else if(empresa.GE_EMAIL === 'wesleyribeiro123@gmail.com') {
          this.alterEnterpriseW = empresa.GE_ENTERPRISE
        }
      });

    },
    async onAlterEnterprise(admin) {
      let user;
      let alterEnterprise;
      if (admin === 'Renan') {
        user = 'gestaoexcel.oficial@gmail.com'
        alterEnterprise = this.alterEnterpriseR

      } else if (admin === 'Wesley') {
        user = 'wesleyribeiro123@gmail.com'
        alterEnterprise = this.alterEnterpriseW
      }

      await this.updateData({
        table: 'GE_PERFIL_USERS',
        fields: {
          'GE_ENTERPRISE': alterEnterprise,
        },
        match: {
          'GE_EMAIL': user
        }
      })

      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Alterado com sucesso! A nova empresa será carregada neste usuário no próximo login...'
      })

    },
    async onBackAdmin(admin) {
      let user;
      let alterEnterprise = 'ADMIN';
      if (admin === 'Renan') {
        user = 'gestaoexcel.oficial@gmail.com'
      } else if (admin === 'Wesley') {
        user = 'wesleyribeiro123@gmail.com'
      }

      await this.updateData({
        table: 'GE_PERFIL_USERS',
        fields: {
          'GE_ENTERPRISE': alterEnterprise,
        },
        match: {
          'GE_EMAIL': user
        }
      })

      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Retornado para ADMIN com sucesso! A nova empresa será carregada neste usuário no próximo login...'
      })

    }
  },
  async created() {
    //~> CARREGANDO O TEMA
    defaultColor();

    //~> INFORMANDO A QTD DE CLIENTES ATIVOS
    const data = await this.rpc({
      function: 'active_users'
    })
    this.activeUsers = data.length


    this.executeQuery();
  }
}
</script>

<style lang="scss">
  @import url('../../globais/GlobalStyles.scss');
  
</style>
